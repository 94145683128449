export const isOtherDomain = (link: string) => {
  const startsWithHttp = link.trim().startsWith('http')

  if (process.server) return startsWithHttp

  if (!startsWithHttp) return false

  const urlHost = new URL(link).host
  const myHost = window.location.host

  return urlHost !== myHost
}
